<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                                v-for="item in supplierList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.monthOffset" filterable placeholder="预估费用周期" size="small" style="width: 300px;">
                        <el-option
                                v-for="item in monthOffsetList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleExport">导出Excel</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="projectName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            label="分包单位名称"
                            width="220">
                        </el-table-column>
                        <el-table-column
                            prop="revenue1"
                            align="center"
                            label="押金余额">
                        </el-table-column>
                        <el-table-column
                            prop="rentSurplus"
                            align="center"
                            label="租金余额">
                            <template slot-scope="scope">
                                <span>{{ scope.row.revenue0 - scope.row.paidMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="paidMoney"
                            align="center"
                            label="已支付金额">
                        </el-table-column>
                        <el-table-column
                            prop="unPaidMoney"
                            align="center"
                            label="未支付金额">
                        </el-table-column>
                        <el-table-column
                            prop="arrears"
                            align="center"
                            label="欠缴情况">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'

export default {
    name: 'EarlyWarn',
    components: {},
    data() {
        return {
            filters: {
                supplierId: '',
                projectId: '',
                monthOffset: 6,
            },
            supplierList: [],
            monthOffsetList: [
                { value: 1, name: '1个月后' },
                { value: 3, name: '3个月后' },
                { value: 6, name: '6个月后' },
                { value: 9, name: '9个月后' },
                { value: 12, name: '12个月后' },
            ],
            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            this.tableLoading = true;
            http.get('v1/payment/supplierPaymentWarnList', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.data;
                    //this.totalNum = req.data.totalCount;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleExport() {}
    }
}
</script>

<style scoped>
</style>
